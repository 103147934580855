@use "@angular/material" as mat;
@include mat.core();

$theme-primary: mat.m2-define-palette(mat.$m2-red-palette);
$theme-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);

@mixin palette($theme) {
  $primary-color: mat.m2-get-color-from-palette($theme, 500);
  ::selection {
    background-color: $primary-color;
  }
  .colored-hover:hover {
    color: $primary-color;
  }
  // swiper-container::part(bullet-active) {background-color: $primary-color}
  //   .swiper-pagination-bullet-active {
  //   background-color: $primary-color;
  // }
  .activated-route {
    border-color: $primary-color !important;
  }
  .mat-mdc-tab:not(.mat-mdc-tab-disabled)
    .mdc-tab-indicator__content--underline {
    border-color: $primary-color;
  }
}

@include palette($theme-primary);

$theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
    ),
    typography: mat.m2-define-typography-config(),
  )
);
@include mat.all-component-themes($theme);

.blue-theme {
  $theme-primary: mat.m2-define-palette(mat.$m2-blue-palette);
  @include palette($theme-primary);

  $theme: mat.m2-define-dark-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-accent,
      ),
      typography: mat.m2-define-typography-config(),
    )
  );
  @include mat.all-component-colors($theme);
}

.green-theme {
  $theme-primary: mat.m2-define-palette(mat.$m2-green-palette);
  @include palette($theme-primary);

  $theme: mat.m2-define-dark-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-accent,
      ),
      typography: mat.m2-define-typography-config(),
    )
  );
  @include mat.all-component-colors($theme);
}

// Dialogo

.dialog-title-container {
  cursor: move;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  a {
    float: right;
    color: white;
  }
  h2 {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
    color: #fff;
    display: flex;
    align-items: center;
  }
}

.mdc-dialog-container {
  padding: 0 !important;
}

.mdc-dialog__content {
  padding: 0 !important;
  margin: 0 !important;
}
