@import "./app/styles/variables";

/* General */

*,::after,::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: #333333;
  margin: 0;
}

a {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-out 0s;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.padding-top-60 {
  padding-top: 60px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.hidden {display: none;}

/* Loading */

.cssloader {
  padding-top: calc(45vh - 25px);
}

.sh1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #354952 transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
}

.sh2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent  transparent #df1a54 transparent ;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}

.sub-title {
  color:rgb(161, 163, 163);
}
/** animation starts here **/
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }

  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.lt {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  margin: 30px auto;
  text-align: center;
  font-weight: 100;
  letter-spacing: 10px;
}

/*  diy load */

.svg-wrapper {
  height: 60px;
	margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 320px;
  padding-top: calc(45vh - 25px);
}

.shape {
  fill: transparent;
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  stroke: #19f6e8;
  animation: draw 2s linear forwards, rotate 2s linear infinite;
}

.text {
  color: #fff;
  font-family: 'Roboto Condensed';
  font-size: 22px;
  letter-spacing: 8px;
  line-height: 32px;
  position: relative;
  width: 100%; /* 设置宽度为100% */
  text-align: center; /* 文字居中 */
  top: -48px; /* 保持文字位置重叠 */
}

@keyframes draw {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 8px;
  }
  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
    stroke-width: 2px;
  }
}
/* media query */

/*xs*/
@media (max-width: $breakpoint-xs) {
  .container {
    max-width: $breakpoint-xs;
    padding-right: 0;
    padding-left: 0;
  }
}

/*sm*/
@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
  .container {
    max-width: 720px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

/*md*/
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
  .container {
    max-width: 960px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

// lg media query with variables
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
  .container {
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

// xl media query
@media (min-width: $breakpoint-lg) {
  .container {
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
